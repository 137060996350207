.install-app {
    background: #f2f2f2;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    height: 90px;
    padding: 10px;
}

.install-app__close-button {
    color: #787878;
    line-height: 70px;
    width: 30px;
}

.install-app__icon {
    height: 70px;
    width: 70px;
}

.install-app__description {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

.install-app__description__title {
    font-size: 16px;
    font-weight: bold;
}

.install-app__description__seller-name {
    padding-top: 4px;
}

.install-app__view {
    line-height: 70px;
    text-align: center;
    vertical-align: middle;
    width: 50px;

    a {
        color: #0d46d9;
        font-size: 18px;
    }
}
