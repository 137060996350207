html {
    color: $typography-text-color;
    font-family: $typography-font-family;
    font-size: 87.5%;
    -webkit-font-smoothing: antialiased;
    line-height: $typography-line-height;
}

body {
    &:after {
        content: 'xxs';
        display: none;

        @include screen-extra-small {
            content: 'xs';
        }

        @include screen-small {
            content: 'sm';
        }

        @include screen-medium {
            content: 'md';
        }

        @include screen-large {
            content: 'lg';
        }
    }

    &.body--flexbox {
        display: flex;
        flex-direction: column;
    }
}
