/* stylelint-disable block-closing-brace-newline-after, at-rule-empty-line-before */
@mixin utility-disabled-styles($trump: false) {
    @if ($trump) {
        cursor: $global-disabled-cursor !important;
        opacity: $global-disabled-opacity !important;
    } @else {
        cursor: $global-disabled-cursor;
        opacity: $global-disabled-opacity;
    }
}

@mixin utility-faded($trump: false) {
    @if ($trump) {
        opacity: $global-disabled-opacity !important;
    } @else {
        opacity: $global-disabled-opacity;
    }
}
/* stylelint-enable */
@mixin utility-hide-element {
    @include size(rem(1px));
    border: 0;
    clip: rect(0 0 0 0);
    margin: rem(-1px);
    overflow: hidden;
    padding: 0;
    position: absolute;
}
