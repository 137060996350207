.adaptive-image {
    flex-shrink: 0;
    width: rem(80px);

    @include screen-extra-small-up {
        width: rem(120px);
    }
}

.adaptive-image--thumbnail {
    width: rem(40px);

    @include screen-extra-small-up {
        width: rem(60px);
    }
}
