.braintree-add-card {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    height: 100%;

    @include screen-extra-small-up {
        height: rem(360px);
        width: rem(400px);
    }
}

.braintree-add-card__form {
    flex: 1 auto;
}

.braintree-add-card__submit {
    flex: 0;
}
