/* stylelint-disable block-closing-brace-newline-after, at-rule-empty-line-before */
@mixin heading-1($trump: false) {
    @if ($trump) {
        font-family: $typography-alternative-font-family !important;
        font-size: $heading-1-font-size !important;
        font-weight: $heading-font-weight !important;
    } @else {
        font-family: $typography-alternative-font-family;
        font-size: $heading-1-font-size;
        font-weight: $heading-font-weight;
    }
}

@mixin heading-2($trump: false) {
    @if ($trump) {
        font-family: $typography-alternative-font-family !important;
        font-size: $heading-2-font-size !important;
        font-weight: $heading-font-weight !important;
    } @else {
        font-family: $typography-alternative-font-family;
        font-size: $heading-2-font-size;
        font-weight: $heading-font-weight;
    }
}

@mixin heading-3($trump: false) {
    @if ($trump) {
        font-family: $typography-alternative-font-family !important;
        font-size: $heading-3-font-size !important;
        font-weight: $heading-font-weight !important;
    } @else {
        font-family: $typography-alternative-font-family;
        font-size: $heading-3-font-size;
        font-weight: $heading-font-weight;
    }
}

@mixin heading-4($trump: false) {
    @if ($trump) {
        font-family: $typography-alternative-font-family !important;
        font-size: $heading-4-font-size !important;
        font-weight: $heading-font-weight !important;
    } @else {
        font-family: $typography-alternative-font-family;
        font-size: $heading-4-font-size;
        font-weight: $heading-font-weight;
    }
}

@mixin heading-5($trump: false) {
    @if ($trump) {
        font-family: $typography-alternative-font-family !important;
        font-size: $heading-5-font-size !important;
        font-weight: $heading-font-weight !important;
    } @else {
        font-family: $typography-alternative-font-family;
        font-size: $heading-5-font-size;
        font-weight: $heading-font-weight;
    }
}

@mixin heading-6($trump: false) {
    @if ($trump) {
        font-family: $typography-alternative-font-family !important;
        font-size: $heading-6-font-size !important;
        font-weight: $heading-font-weight !important;
    } @else {
        font-family: $typography-alternative-font-family;
        font-size: $heading-6-font-size;
        font-weight: $heading-font-weight;
    }
}

@mixin text-large($trump: false) {
    @if ($trump) {
        font-size: $typography-large-font-size !important;
    } @else {
        font-size: $typography-large-font-size;
    }
}

@mixin text-base($trump: false) {
    @if ($trump) {
        font-size: $em-base !important;
    } @else {
        font-size: $em-base;
    }
}

@mixin text-small($trump: false) {
    @if ($trump) {
        font-size: $typography-small-font-size !important;
    } @else {
        font-size: $typography-small-font-size;
    }
}

@mixin text-error($trump: false) {
    @if ($trump) {
        color: $color-red !important;
    } @else {
        color: $color-red;
    }
}

@mixin text-success($trump: false) {
    @if ($trump) {
        color: $color-green !important;
    } @else {
        color: $color-green;
    }
}

@mixin text-default($trump: false) {
    @if ($trump) {
        color: $typography-text-color !important;
    } @else {
        color: $typography-text-color;
    }
}

@mixin text-highlight($trump: false) {
    @if ($trump) {
        color: $typography-highlight-color !important;
    } @else {
        color: $typography-highlight-color;
    }
}
/* stylelint-enable */
