.badge {
    background-color: $badge-default-background-color;
    border-radius: $badge-border-radius;
    color: $badge-default-text-color;
    display: inline-block;
    padding: 0 rem(5px);
}

.badge--rounded {
    border-radius: rem(10px);
    line-height: 1;
    min-width: rem(10px);
    padding: rem(3px) rem(7px);
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.badge--light {
    background-color: $badge-light-background-color;
    color: $badge-light-text-color;
}

.badge--dark {
    background-color: $badge-dark-background-color;
    color: $badge-dark-text-color;
}

.badge--semi-transparent {
    background-color: $badge-semi-transparent-background-color;
    color: $badge-semi-transparent-text-color;
}

.badge__text {
    color: $badge-text-color;
}
