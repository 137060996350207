.panel {
    background: $panel-background-color;
    border: $panel-border-width solid $panel-border-color;
    border-radius: $global-border-radius;
    min-height: rem(1px);
}

.panel--light {
    background: transparent;
    border-color: $panel-light-border-color;
}

.panel--adaptive {
    border-radius: 0;
    border-width: $panel-border-width 0;

    @include screen-small-up() {
        border-radius: $global-border-radius;
        border-width: $panel-border-width;
    }
}

.panel--shadow {
    .panel__row:not(:first-child) {
        .panel__section {
            border-top: $panel-shadow-border;
        }
    }

    @include elegant-box;
    border: none;
}

.panel--square {
    border-radius: 0;

    .panel__row:first-child {
        .panel__section {
            border-radius: 0;
        }
    }

    .panel__row:last-child {
        .panel__section {
            border-radius: 0;
        }
    }
}

.panel__section,
.panel__row {
    border-color: inherit;
}

.panel__row {
    @include clearfix;
}

.panel__section {
    border-style: solid;
    border-width: $panel-border-width 0 0;
    float: left;
    margin: (-$panel-border-width) 0 0;
    padding: rem(20px) rem(15px);
    width: $panel-section-width;

    > :last-child {
        margin: 0;
        padding: 0;
    }

    .panel--adaptive & {
        border-width: 0;

        @include screen-small-up() {
            border-width: $panel-border-width 0 0;
        }
    }

    .panel--shadow & {
        border-width: 0;
    }
}

.panel__section--split {
    border-right-width: $panel-border-width;
    width: $panel-section-split-width;

    &:last-child {
        border-right: none;
    }

    .panel__row:first-child & {
        &:first-child {
            border-radius: $global-border-radius 0 0;
        }

        &:last-child {
            border-radius: 0 $global-border-radius 0 0;
        }
    }

    .panel--adaptive & {
        width: $panel-section-width;

        @include screen-small-up() {
            border-right-width: $panel-border-width;
            width: $panel-section-split-width;
        }
    }
}

.panel__section--fill {
    background-color: $panel-section-fill-background-color;

    .panel--light & {
        background-color: $panel-light-fill-background-color;
    }
}
