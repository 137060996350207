$typography-font-family: sans-serif !default;
$typography-alternative-font-family: $typography-font-family !default;

$typography-text-color: $color-charcoal !default;
$typography-line-height: 1.2 !default;

$typography-weight-regular: 400 !default;
$typography-weight-bold: 700 !default;

$typography-small-font-size: rem($em-base - 3) !default;
$typography-large-font-size: rem($em-base + 4) !default;

$typography-highlight-color: color($color-red, 200) !default;
$typography-disabled-color: color($typography-text-color, 200) !default;
$typography-heading-description-color: $color-gray !default;
