.popover__container {
    position: relative;
    width: 100%;
}

.popover--small {
    padding: rem(15px);
    width: 100%;

    @include screen-extra-small-up {
        max-width: rem(280px);
        min-width: rem(220px);
        width: auto;
    }
}

.popover {
    background: white;
    border: 1px solid $popover-border-color;
    box-shadow: $popover-shadow;
    display: block;
    margin-top: rem(15px);
    z-index: $z-index-popover;

    @include position(fixed, auto 0 auto auto);

    @include screen-extra-small-up {
        @include position(absolute, auto 0 auto auto);

        &:before,
        &:after {
            @include triangle(rem(20px), rgba($popover-border-color, 1), up);
            @include position(absolute, rem(-10px) calc(50% - 10px) null null);
            content: '';
        }

        &:after {
            border-bottom-color: #fff;
        }
    }
}

.popover--left {
    @include screen-extra-small-up {
        @include position(absolute, auto auto auto auto);
    }
}

.popover--arrow-top-right {
    &:before,
    &:after {
        @include position(absolute, rem(-10px) rem(20px) null null);
    }
}

.popover__backdrop {
    background: $popover-backdrop-background;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-popover-backdrop;

    @include screen-extra-small-up {
        background: transparent;
    }
}

.popover__backdrop--always-visible {
    @include screen-extra-small-up {
        background: $popover-backdrop-background;
    }
}
