.util-text-large {
    @include text-large(true);
}

.util-text-small {
    @include text-small(true);
}

.util-text-error {
    @include text-error(true);
}

.util-text-success {
    @include text-success(true);
}

.util-text-default {
    @include text-default(true);
}

.util-text-highlight {
    @include text-highlight(true);
}

.util-text-h1 {
    @include heading-1(true);
}

.util-text-h2 {
    @include heading-2(true);
}

.util-text-h3 {
    @include heading-3(true);
}

.util-text-h4 {
    @include heading-4(true);
}

.util-text-h5 {
    @include heading-5(true);
}

.util-text-h6 {
    @include heading-6(true);
}

.util-text-regular {
    font-weight: $typography-weight-regular !important;
}

.util-text-bold {
    font-weight: $typography-weight-bold !important;
}

.util-text-italic {
    font-style: italic !important;
}

.util-text-align-center {
    text-align: center !important;
}

.util-text-align-right {
    text-align: right !important;
}

.util-text-align-justify {
    text-align: justify !important;
}

.util-break-word {
    word-wrap: break-word !important;
}

.util-text-uppercase {
    text-transform: uppercase !important;
}
