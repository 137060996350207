@mixin adaptive-form-group {
    @include position(fixed, null null 0 0);
    width: 100%;
    z-index: $z-index-1;
}

.form__row {
    margin: 0 0 $global-element-margin-base;
}

.form__row--error {
    .form__label {
        color: $form-label-error-color;
    }

    .form__input,
    .form__icon {
        border-color: $form-input-error-border-color;

        &:focus {
            border-color: $form-input-error-border-color;
        }
    }

    .form__icon--highlighted {
        background-color: $form-input-error-border-color;
    }
}

.form__row--inline {
    align-items: center;
    display: flex;

    .form__label {
        margin: 0;
    }
}

.form__label {
    display: block;
    font-weight: $form-label-font-weight;
    margin: 0 0 $spacing-values-xxxs 0;

    > .form__input,
    > .form__input-group,
    > .form__select-group {
        margin: $spacing-values-xxxs 0 0 0;
    }
}

.form__label--disabled {
    @include utility-disabled-styles;
}

.form__label--list {
    display: inline-block;
    margin: 0 0 rem(10px) 0;
    position: relative;
}

.form__icon {
    @include size($global-element-height-base 100%);
}

.form__icon--highlighted {
    @include size($button-height $button-height);
    @include border-radius-left-only($global-border-radius);
    background-color: $form-icon-highlighted-background-color; //$color-charcoal;
    border: $form-icon-highlighted-border-color solid;
    border-width: rem(1px) 0 rem(1px) rem(1px);

    + .form__input {
        @include border-radius-right-only($global-border-radius);
    }
}

.form__button {
    > .button {
        @include border-radius-right-only($global-border-radius);
        border-left: none;
    }
}

.form__input,
.form__select {
    @include appearance(none);
    @include size($form-input-width $form-input-height);
    background-color: $form-input-background-color;
    border-radius: $global-border-radius;
    outline: none;
    padding: 0 $form-input-padding;
    position: relative;

    &[disabled] {
        @include utility-disabled-styles;
    }

    &:focus {
        border-color: $form-input-focus-border-color;
    }

    .form__label > & {
        color: $form-input-color;
    }
}

.form__input {
    border: $form-border-width solid;
    border-color: $form-input-border-color;

    &[maxlength='1'] {
        text-align: center;
        width: $form-input-width-maxlength-1;
    }

    &[maxlength='2'] {
        text-align: center;
        width: $form-input-width-maxlength-2;
    }

    &[maxlength='3'] {
        text-align: center;
        width: $form-input-width-maxlength-3;
    }
}

.form__input--with-button {
    border-right: none;
}

.form__voucher-input:not([disabled]) {
    background-color: $color-white;
}

.form__select {
    @include size(100% $global-element-height-base);
    background-color: transparent;
    border: none;
    outline: none;
    padding-right: rem(30px);
    z-index: 1;
}

.form__radio,
.form__checkbox {
    @include utility-hide-element;
}

.form__radio {
    &:checked {
        ~ .form__radio-mock {
            &:before {
                background-color: $form-radio-checked-background-color;
                border: $form-radio-checked-border-color rem(7px) solid;
            }

            &.action:before {
                border: $button-action-background-color rem(7px) solid;
            }
        }
    }

    ~ .form__radio-mock {
        display: inline-block;

        &:before {
            @include size(rem(20px));
            background-color: $form-radio-background-color;
            border: $form-radio-border-color rem(1px) solid;
            border-radius: 100%;
            content: '';
            display: inline-block;
            margin: 0 rem(5px) 0 0;
            vertical-align: rem(-5px);
        }
    }
}

.form__checkbox {
    &:checked {
        ~ .form__checkbox-mock {
            &:before {
                background: $form-checkbox-checked-background-color url('#{$form-checkmark-base64-url}') no-repeat rem(2px) rem(4px);
                background-size: rem(14px) rem(10px);
                border-color: $form-checkbox-checked-border-color;
            }
        }
    }

    ~ .form__checkbox-mock {
        display: inline-block;

        &:before {
            @include size(rem(20px));
            background-color: $form-checkbox-background-color;
            border: $form-checkbox-border-color rem(1px) solid;
            border-radius: $global-border-radius;
            content: '';
            display: inline-block;
            margin: 0 rem(5px) 0 0;
            vertical-align: rem(-5px);
        }

        &.form__checkbox-mock--highlighted:before {
            background-color: #fafafa;
            border: 2px solid #c5003e;
        }
    }
}

// Form input grouping will require nesting for proper border rounding
/* stylelint-disable max-nesting-depth */
.form__input-group {
    align-items: center;
    border-collapse: separate;
    border-spacing: 0;
    display: flex;
    position: relative;
    width: 100%;

    .form__input {
        border-radius: 0;
        padding: 0 0 0 rem(10px);
        width: 100%;

        &:first-child {
            @include border-radius-left-only($global-border-radius);
        }

        &:last-child {
            @include border-radius-right-only($global-border-radius);
        }

        &:only-child {
            border-radius: $global-border-radius;
        }
    }

    .form__icon {
        align-items: center;
        display: flex;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: $button-height;
        z-index: 1;

        &:first-child {
            + .form__input {
                @include border-radius-left-only($global-border-radius);

                &:last-child {
                    border-radius: $global-border-radius;
                }
            }
        }

        + .form__input {
            padding: 0 0 0 $button-height;
        }
    }

    .form__icon--clickable {
        cursor: pointer;
        pointer-events: auto;
    }
}
/* stylelint-enable max-nesting-depth */

.form__input-group--adaptive-xs {
    @include screen-extra-small {
        @include adaptive-form-group;
    }
}

.form__input-group--adaptive-sm {
    @include screen-small {
        @include adaptive-form-group;
    }
}

.form__input-group--adaptive-md {
    @include screen-medium {
        @include adaptive-form-group;
    }
}

.form__input-group--adaptive-lg {
    @include screen-large {
        @include adaptive-form-group;
    }
}

.form__select-group {
    @include size(100% $global-element-height-base);
    background-color: $form-input-background-color;
    border: rem(1px) solid;
    border-color: $form-input-border-color;
    border-radius: $global-border-radius;
    overflow: hidden;
    position: relative;

    &:after {
        @include position(absolute, 50% rem(15px) null null);
        @include triangle(rem(10px), $form-select-dropdown-icon-color, down);
        content: '';
        margin: rem(-1px) 0 0;
    }
}
