$slotbooking-border: rem(1px) solid $panel-border-color;
$timegroup-background: $simple-layout-background;

.checkout__panel {
    transition: opacity $global-transition-speed ease-in-out;
}

.checkout__panel--disabled {
    opacity: $global-disabled-opacity;
}

.checkout__payment-icon {
    @include size($button-height);

    @include screen-small-up {
        height: $button-height;
        width: rem(60px);
    }
}

.checkout-summary {
    padding: 0;
    width: 100%;
}

.checkout-summary__checkout-button {
    height: rem(50);
    text-transform: $basket-summary-button-text-transform;
    vertical-align: middle;
    width: 100%;
}

.checkout-summary__items {
    @include margin(0 0 rem(15) 0);
    @include padding(rem(15));
    @include elegant-box;
    background-color: $basket-summary-background-lg;
    border: none;
    border-radius: $global-border-radius;
    display: block;
}

.checkout-summary__item {
    @include padding(rem(7) 0);
    display: block;
    line-height: 1;
}

.checkout-summary__field {
    display: inline-block;
    margin: 0;
    width: 50%;
}

.checkout-summary__field__value {
    text-align: right;
}

.checkout-summary__voucher {
    background-color: color($color-gray, 50);
    margin: rem(5px) 0 $spacing-values-xxs 0;
    padding: $spacing-values-xs;
}

.checkout-summary__voucher-alert {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.checkout-summary__total {
    @include padding(rem(10) 0);
    @include text-large();
    margin-top: $spacing-values-xs;

    .checkout-summary__field {
        color: $basket-summary-total-color;
        font-weight: $typography-weight-bold;
    }
}

.checkout-summary__voucher-link {
    a {
        font-weight: normal;
    }
}

.checkout-fulfilment--disabled {
    text-decoration: $checkout-fulfilment-disabled-text-decoration;
}

.slotbooking-container {
    background: $form-input-background-color;
    overflow: auto;
}

.slotbooking__header {
    border-bottom: $slotbooking-border;

    @include screen-small-up {
        display: none;
    }
}

.slotbooking__days-container {
    padding-top: $spacing-values-xs;
    position: relative;
}

.slotbooking-days__list,
.slotbooking-days__scroll-arrows {
    background: $panel-background-color;
    border: $slotbooking-border;
    border-left: 0;
    border-right: 0;
    overflow: hidden;
    padding: 0 rem(40px);
    white-space: nowrap;

    li {
        display: inline-block;
    }

    .slotbooking__day {
        border-left: $slotbooking-border;
        cursor: pointer;
        font-weight: $typography-weight-bold;
        padding: $spacing-values-xxs;
        text-align: center;
        width: rem(150px);

        &--active {
            background: $button-primary-background-color;
            color: $button-primary-text-color;
            cursor: default;
        }

        &--disabled {
            text-decoration: line-through;

            @include utility-disabled-styles();
        }
    }
}

.slotbooking-days__scroll-arrow--left,
.slotbooking-days__scroll-arrow--right {
    background: $color-white;
    border: $slotbooking-border;
    cursor: pointer;
    padding: $spacing-values-xxs;
    position: absolute;
    width: rem(40px);
    z-index: 1; // Fixes issue with disabled days being rendered over the arrows (due to opacity, https://philipwalton.com/articles/what-no-one-told-you-about-z-index/ )
}

.slotbooking-days__scroll-arrow--left {
    border-left: 0;
}

.slotbooking-days__scroll-arrow--right {
    border-right: 0;
    right: 0;
}

.slotbooking__slots {
    display: block;
    min-height: rem(200px);
    overflow: auto;
    padding-bottom: $spacing-values-xs;
    transition: opacity $global-transition-speed ease-in-out;
    width: 100%;

    @include screen-small-up {
        display: flex;
    }
}

.slotbooking__slots--disabled {
    .slotbooking-slots__column {
        opacity: $global-disabled-opacity;
    }
}

.slotbooking__no-slots-message {
    background: $modal-background;
    border-radius: $global-border-radius;
    box-shadow: $modal-shadow;
    margin: $spacing-values-xl $spacing-values-xs;
    padding: $spacing-values-xs;

    @include screen-small-up {
        margin: $spacing-values-xl;
    }
}

.slotbooking-slots__column {
    flex: 1;

    @include screen-small-up {
        &:first-child {
            .slotbooking__slot {
                border-right: $slotbooking-border;
            }
        }

        &:last-child {
            .slotbooking__slot {
                border-left: $slotbooking-border;
            }
        }
    }
}

.slotbooking-slots__header {
    color: $panel-border-color;
    padding: $spacing-values-xxs 0;
    text-align: center;
    text-transform: uppercase;
}

.slotbooking-slots__list {
    background: $panel-background-color;
    border-bottom: $slotbooking-border;
    border-left: 0;
    padding: 0;

    li {
        display: block;
    }
}

.slotbooking__slot {
    background-color: $color-white;
    border-bottom: $slotbooking-border;
    cursor: pointer;
    padding: $spacing-values-xs;

    &:last-child {
        border-bottom: none;
    }

    &--active {
        background: $button-primary-background-color;
        color: $button-primary-text-color;
    }

    &--disabled {
        color: $typography-disabled-color;
        cursor: not-allowed;
        text-decoration: line-through;
    }
}

.slotbooking-slot__price {
    color: $color-gray;

    .slotbooking__slot--active & {
        color: $button-primary-text-color;
    }

    .slotbooking__slot--disabled & {
        text-decoration: line-through;
    }
}

.slotbooking-slot__warning {
    fill: $alert-warning-strong-background-color;

    @include size(rem(16px));
}

.slotbooking__timegroup-header {
    border-bottom: $slotbooking-border;
    color: $color-gray;
    padding: $spacing-values-xs;
    text-align: center;
    text-transform: uppercase;
}

.slotbooking__timegroup {
    border-right: $slotbooking-border;

    &:last-child {
        border-right: none;
    }
    @include screen-small-up {
        width: 33.333333%;
    }
}

.slotbooking__timegroup--is-empty {
    display: none;

    @include screen-small-up {
        display: block;
    }
}

.slotbooking__timegroup-container {
    background-color: $timegroup-background;

    @include screen-small-up {
        display: flex;
    }
}

.checkout__split-order {
    background-color: $split-order-background-color;
    padding: rem(30px) rem(20px);
}

.checkout__split-order__container {
    align-items: center;
    display: flex;
    width: 100%;
}

.checkout__split-order__icon {
    fill: $split-order-icon-color;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 rem(20px);
}

.checkout__split-order__message {
    color: $split-order-text-color;
}

.checkout__unavailable-slots-modal {
    @include screen-small-up {
        width: rem(450px);
    }
}

.checkout__unavailable-slots-modal-icon {
    background-color: $color-red;
    border-radius: 50%;
    color: white;
    height: rem(64px);
    margin: $spacing-values-xs auto;
    padding: rem(15px);
    width: rem(64px);

    .icon {
        height: 100%;
        width: 100%;
    }
}

.checkout__unavailable-slots-modal-body {
    font-size: rem(18px);
}

.slotbooking__change-slot-button {
    margin-bottom: rem(1px);
    margin-top: $spacing-values-xs;
}
