.button {
    @include button-base;

    > .icon {
        margin: 0 rem(10px) 0 0;
    }
}

.button--small {
    height: $button-small-height;
    line-height: $button-small-line-height;
}

.button--large {
    height: $button-large-height;
    line-height: $button-large-line-height;
}

.button--disabled {
    @include utility-disabled-styles;
}

.button--dropdown {
    position: relative;

    &:after {
        @include triangle(rem(10px), black, down);
        content: '';
        display: inline-block;
        margin: rem(-2px) 0 0 rem(10px);
        vertical-align: middle;
    }

    > .dropdown {
        @include position(absolute, $button-dropdown-position-top null null 0);
    }
}

.button--primary {
    background-color: $button-primary-background-color;
    border-color: transparent transparent $button-primary-border-color;
    color: $button-primary-text-color;

    &:hover {
        background-color: $button-primary-background-color-alternate;
    }

    &.button--disabled {
        &:hover {
            background-color: $button-primary-background-color;
        }
    }

    &.button--light {
        border-color: $button-primary-light-border-color;
        color: $button-primary-light-text-color;
    }

    &.button--dropdown {
        &:after {
            border-top-color: $button-primary-text-color;
        }
    }

    &.button--no-hover {
        &:hover {
            background-color: $button-primary-background-color;
        }
    }
}

.button--secondary {
    background-color: $button-secondary-background-color;
    border-color: $button-secondary-border-color;
    color: $button-secondary-text-color;

    &:hover {
        background-color: $button-secondary-background-color-alternate;
    }

    &.button--disabled {
        &:hover {
            background-color: $button-secondary-background-color;
        }
    }

    &.button--light {
        border-color: $button-secondary-light-border-color;
        color: $button-secondary-light-text-color;
    }

    &.button--dropdown {
        &:after {
            border-top-color: $button-secondary-text-color;
        }
    }

    &.button--no-hover {
        &:hover {
            background-color: $button-secondary-background-color;
        }
    }
}

.button--link {
    background: transparent;
    border: 0;
    display: inline;
    height: auto;
    line-height: normal;
    padding: 0;
    text-transform: none;

    @include anchor-base();
}

.button--action {
    background-color: $button-action-background-color;
    border-color: $button-action-border-color;
    color: $button-action-text-color;

    &:hover {
        background-color: $button-action-background-color-alternate;
    }

    &.button--disabled {
        &:hover {
            background-color: $button-action-background-color;
        }
    }

    &.button--light {
        border-color: $button-action-light-border-color;
        color: $button-action-light-text-color;
    }

    &.button--dropdown {
        &:after {
            border-top-color: $button-action-text-color;
        }
    }

    &.button--no-hover {
        &:hover {
            background-color: $button-action-background-color;
        }
    }
}

.button--icon {
    padding: 0;
    text-align: center;
    width: $button-height;

    > .icon {
        margin: 0;
    }
}

.button--light {
    background-color: transparent;
    border: rem(1px) solid;

    &:hover {
        background-color: transparent;
    }

    &.button--disabled {
        &:hover {
            background-color: transparent;
        }
    }

    &.button--no-hover {
        &:hover {
            background-color: transparent;
        }
    }
}

.button--extended,
.button--adaptive-xs,
.button--adaptive-sm,
.button--adaptive-md,
.button--adaptive-lg {
    text-align: center;
    width: 100%;
}

.button--adaptive-xs {
    @include screen-extra-small-up() {
        text-align: left;
        width: auto;
    }
}

.button--adaptive-sm {
    @include screen-small-up() {
        text-align: left;
        width: auto;
    }
}

.button--adaptive-md {
    @include screen-medium-up() {
        text-align: left;
        width: auto;
    }
}

.button--adaptive-lg {
    @include screen-large() {
        text-align: left;
        width: auto;
    }
}

.button--icon-adaptive {
    padding: 0;
    text-align: center;
    width: $button-height;

    > .icon {
        margin: 0;

        @include screen-extra-small-up() {
            margin: 0 rem(10px) 0 0;
        }
    }

    > .button-icon-adaptive__label {
        display: none;

        @include screen-extra-small-up() {
            display: inline;
        }
    }

    @include screen-extra-small-up() {
        padding: 0 rem(10px);
        width: auto;
    }
}

.button--paypal {
    background-color: $button-paypal-background-color;
    border-color: transparent transparent $button-paypal-background-color-alternate;
    border-radius: $global-border-radius;
    color: $button-paypal-text-color;
    text-transform: none;

    &:hover {
        background-color: $button-paypal-background-color-alternate;
    }

    &.button--disabled {
        &:hover {
            background-color: $button-paypal-background-color;
        }
    }

    &.button--light {
        background-color: transparent;
        border: rem(1px) solid;
        border-color: $color-paypal;
        color: $color-paypal;
        text-transform: $button-text-case;

        &:hover {
            background-color: transparent;
        }
    }

    &.button--dropdown {
        &:after {
            border-top-color: $button-paypal-text-color;
        }
    }
}

.button--close {
    background: none;
    border: none;
}

.button-group {
    display: flex;

    .button {
        border-radius: 0;
    }

    .button:first-child {
        border-radius: $global-border-radius 0 0 $global-border-radius;
    }

    .button:last-child {
        border-radius: 0 $global-border-radius $global-border-radius 0;
    }

    &.button-group--no-internal-borders {
        .button:not(:first-child) {
            border-left: none !important;
        }

        .button:not(:last-child) {
            border-right: none !important;
        }
    }
}
