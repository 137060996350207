.divider {
    background-color: $divider-default-background-color;
    border: none;
    height: rem(1px);
}

.divider--thick {
    height: rem(2px);
}

.divider--thicker {
    height: rem(4px);
}

.divider--primary {
    background-color: $divider-primary-background-color;
}

.divider--secondary {
    background-color: $divider-secondary-background-color;
}
