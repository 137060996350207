$bop-lightgray: #f9f5f3;

.bop__gallery-carousel-nav {
    margin-top: $spacing-values-xs;
    padding: 0;
    text-align: center;
    width: 100%;

    > li {
        display: inline-block;
        margin: 0 $spacing-values-xxxs;
    }
}

.bop__gallery-carousel-nav-item {
    @include size(10px);
    @include transition(background-color .3s ease);
    background-color: rgba($color-secondary, .5);
    border: none;
    border-radius: 10px;
    margin: 0;
    padding: 0;

    // React Slick slider is currently unable to pass custom class to active pagination elements. To be fixed in https://github.com/akiran/react-slick/pull/1270
    .slick-active > & {
        background-color: rgba($color-secondary, 1);
    }
}

.bop__gallery-carousel-item {
    padding: 0 $spacing-values-xxxs;
}

.bop__gallery-carousel-image {
    width: 100%;
}

.bop__badge {
    background: $badge-category-background-color;
    color: white;
    display: inline-block;
    margin: 1px;
    padding: 3px;
    text-align: center;
    width: 100%;
}

.bop__badge-container {
    width: 130px;
}

.bop__form-section {
    border-bottom: 1px solid $color-lightgray;
    border-top: 1px solid $color-lightgray;
}

.bop__form__availability-anonymous {
    align-items: center;
    border: 1px solid $color-lightgray;
    border-radius: 3px;
    display: inline-flex;
    justify-content: space-between;
    margin-top: rem(20px);
    width: 100%;

    .bop__form__availability-anonymous-label {
        color: $color-gray;
        margin: rem(15px);
        white-space: nowrap;
    }

    .bop__form__availability-anonymous-login {
        align-items: center;
        display: inline-flex;
        justify-content: flex-end;
    }

    button {
        margin: rem(15px);
    }

    @include screen-up-to-small {
        .bop__form__availability-anonymous-label {
            margin: rem(5px);
        }

        .bop__form__availability-anonymous-login {
            strong {
                margin: rem(5px);
                margin-right: 0;
            }
        }

        button {
            margin: rem(5px);
        }
    }
}

.bop__form__availability-section {
    margin-top: rem(20px);
    width: 100%;
}

.bop__form__buy-box {
    margin-bottom: rem(20px);
    margin-top: rem(20px);
    width: 100%;

    .bop__form__buy-box__basket-overlay {
        display: inline-flex;
    }

    .bop__form__buy-box-error {
        margin-bottom: 0;
        margin-top: rem(15px);
        width: 100%;
    }
}

.bop__form__buy-box__favourite {
    align-items: center;
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;

    .bop__form__buy-box__favourite-icon {
        margin-right: 5px;
    }

    .bop__form__buy-box__favourite-last-purchased {
        display: flex;
        flex-direction: column;
    }
}

.bop__form__buy-box__basket-form {
    display: inline-flex;
    width: 100%;

    .bop__form__buy-box__basket-form-input {
        border-radius: 3px;
        height: 100%;
        width: 60px;
    }

    .bop__form__buy-box__basket-button-text {
        padding-left: rem(30px);
        padding-right: rem(30px);
    }
}

.bop__badge-parent {
    bottom: 0;
    display: flex;
    left: 0;
    margin-bottom: rem(20px);
    position: absolute;
    width: 100%;
}

.bop__badge--icon {
    bottom: rem(20px);
    height: rem(57px);
    pointer-events: none;
    width: rem(49px);
    z-index: 2;

    &:nth-child(n+3) {
        display: none;
    }
}

.bop__gallery {
    position: relative;
}

.bop__gallery-container {
    margin-bottom: $spacing-values-md;
    overflow: hidden;
    position: relative;
}

.bop__gallery-nav {
    &:hover {
        .bop__gallery-nav-item {
            opacity: .5;

            &:hover,
            &--is-active {
                opacity: 1;
            }
        }
    }
}

.bop__gallery-nav-item {
    border-radius: $global-border-radius;
    cursor: pointer;
    display: inline-block;
    margin: 0 rem(8px) rem(8px) 0;
    padding: rem(5px);
    transition: opacity 175ms ease-in-out;

    &--is-active {
        border: 1px solid #cfcfcf;
    }

    img {
        width: 100%;
    }
}

.bop__mobile-gallery {
    margin-bottom: $spacing-values-md;
    position: relative;

    @include screen-small-up {
        display: none;
    }
}

.bop__gallery-zoom-info {
    color: $color-gray;
    left: 0;
    position: absolute;
    text-transform: uppercase;
    top: 0;
}

.bop__gallery-zoom-icon {
    @include size(rem(12px));
    fill: $color-gray;
    margin: 0 rem(5px) 0 0;
    vertical-align: text-bottom;
}

.bop__title {
    color: $bop-title-color;
    font-size: rem(26px);
    font-weight: 700;
    margin-bottom: $spacing-values-sm;
}

.bop__price {
    font-size: rem(13px);
    line-height: 1;
}

.bop__price--slash {
    color: $color-gray;
    text-decoration: line-through;
}

.bop__final-price {
    font-size: rem(18px);
}

.bop__promotion {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: $spacing-values-xs;
    overflow: hidden;
}

.bop__promotion-badge {
    background-color: $promotion-discount-badge-background;
    border-radius: $global-border-radius;
    color: $color-white;
    display: inline-block;
    font-family: $typography-font-family;
    font-size: rem(14px);
    margin-right: rem(5px);
    padding: rem(2px) rem(6px);
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

.bop__promotion-badge--free-gift {
    background-color: $promotion-free-gift-color;
    flex: 0 0 auto;
}

.bop__promotion-badge--preorder {
    background-color: $preorder-badge-background;
    flex: 0 0 auto;
}

.bop__promotion-description {
    color: $promotion-discount-badge-background;
    flex: 1 1 100%;
    margin-right: rem(10px);
}

.bop__promotion-description--free-gift {
    color: $promotion-free-gift-color;
}

.bop__promotion-description--preorder {
    color: $preorder-badge-background;
}

.bop__reduced {
    color: $promotion-discount-badge-background;
}

.bop__content-headline {
    font-size: rem(24px);
    font-weight: 400;
    margin-bottom: $spacing-values-xs;
}

.bop__content-body {
    line-height: 1.4;
    margin-bottom: $spacing-values-sm;

    table {
        display: block;
        width: 100%;

        th {
            background: #f1eeec;
            color: $color-charcoal;
            font-weight: 700;
        }

        td,
        th {
            border: 1px solid #ccc;
            padding: rem(6px);
            text-align: left;
        }
    }
}

.bop__useful-links {
    margin: rem(20px) 0 rem(30px);

    @include screen-up-to-extra-small {
        display: none;
    }

    > h5 {
        background-color: $bop-lightgray;
        color: $color-charcoal;
        font-size: rem(19px);
        font-weight: 600;
        margin-bottom: rem(3px);
        padding: rem(10px) rem(15px);
    }

    > ul {
        border: 1px solid $bop-lightgray;
        border-top: none;
        list-style: none;
        outline: 3px solid #fff;
        padding: rem(15px) rem(17px) rem(2px);
        position: relative;
        z-index: 10;

        > li {
            font-size: rem(13px);
            margin-bottom: rem(15px);
            position: relative;

            > svg {
                @include position(absolute, -1px null null 0);
                fill: color($color-gray, 400) !important;
                margin-right: rem(10px);
            }

            > a {
                display: inline-block;
                margin-left: rem(20px);
            }
        }
    }
}

.bop__basket-indicator {
    background-color: $bop-basket-indicator-background;
    border-radius: $global-border-radius;
    display: flex;
    justify-content: space-between;
    padding: rem(15px);

    a {
        color: #555;
        text-decoration: underline;

        &:last-child {
            @include screen-up-to-extra-small {
                display: none;
            }
        }
    }

    @include screen-small-up {
        border: solid tint($color-gray, 50%);
        border-width: rem(1px);
        margin: 0 0 rem(-1px);
    }
}

.bop__restricted-message {
    align-items: center;
    background-color: $bop-lightgray;
    display: flex;
    height: auto;
    line-height: 1.4;
    margin: 15px 0;
    padding: 10px;
    width: 100%;

    em {
        color: $color-primary;
        font-style: normal;
        font-weight: bold;
    }

    p {
        margin: 0;
    }
}

.bop__restricted-icon {
    color: #e46257;
    flex-shrink: 0;
    height: 28px;
    margin-right: 10px;
    width: 28px;
}
