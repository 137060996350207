.util-cursor-default {
    cursor: default !important;
}

.util-cursor-pointer {
    cursor: pointer !important;
}

.util-cursor-disabled {
    cursor: $global-disabled-cursor !important;
}
