.basket__container {
    display: flex;
    flex-direction: column;
    @include screen-medium-up() {
        display: block;
    }
}

.basket__header {
    margin: $spacing-values-sm 0;
    order: 2;
}

.basket-alerts {
    order: 3;
}

.basket-supplementary__title {
    @include margin(0 0 rem(10));
    @include text-large();
    font-weight: $typography-weight-bold;
}

.basket__itemsList {
    order: 4;
}

.basket__close-button {
    height: auto;
    line-height: $basket-close-button-line-height;
    padding-left: 0;
    text-align: right;
}

/* -------------Basket summary box style------------ */
.basket-summary {
    @mixin pricesPinnedToBottom {
        @include position(fixed, null null 0 0);
        display: flex;
        flex-flow: row wrap;
        order: 1;
        padding: 0;
        width: 100%;
        z-index: 100;
    }

    @mixin pricesInBox {
        @include screen-up-to-medium() {
            margin-top: $spacing-values-xs;
        }
    }

    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-up-to-medium() {
            @include pricesPinnedToBottom();
        }
    } @else {
        @include pricesInBox();
    }

    .basket-summary__checkout-button-overlay {
        @mixin chcekoutButtonPinnedToBottom {
            @include position(fixed, null null 0 0);
            z-index: 100;
        }

        @if ($basket-summary-mobile-pin-prices-to-bottom == false) {
            @include screen-up-to-medium() {
                @include chcekoutButtonPinnedToBottom();
            }
        }

        @include screen-medium-up() {
            @include margin(rem(15) 0 0 0);
        }
        background-color: $color-white;
        width: 100%;
    }

    .basket-summary__checkout-button {
        flex-grow: 2;
        height: rem(50);
        text-transform: $basket-summary-button-text-transform;
        vertical-align: middle;
        width: 100%;

        @include screen-small-up() {
            height: rem(80);
            width: 100%;
        }

        @include screen-medium-up() {
            height: rem(50);
            width: 100%;
        }
    }
}

.basket-summary__title {
    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-up-to-medium() {
            display: none;
        }
    }
}

.basket-summary__items {
    @mixin pricesPinnedToBottom {
        background-color: $basket-summary-background;
        display: flex;
        flex-grow: 4;
    }

    @mixin pricesInBox {
        @include padding(rem(15));
        @include elegant-box;
        background-color: $basket-summary-background-lg;
        border: none;
        border-radius: $global-border-radius;
        display: block;
    }

    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-up-to-medium() {
            @include pricesPinnedToBottom();
        }
        @include screen-medium-up() {
            @include pricesInBox();
        }
    } @else {
        @include pricesInBox();
    }
}

.basket-summary__item {
    @mixin pricesPinnedToBottom {
        @include padding(rem(15) 0);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
    }

    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-up-to-medium() {
            @include pricesPinnedToBottom();
        }
    }
    display: block;
    line-height: 1;
    @include padding(rem(7) 0);
}

.basket-summary__field {
    @mixin pricesPinnedToBottom {
        text-align: center;
    }

    @mixin pricesInBox {
        display: inline-block;
        text-align: left;
        width: 50%;
    }

    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-up-to-medium() {
            @include pricesPinnedToBottom();
        }
        @include screen-medium-up() {
            @include pricesInBox();
        }
    } @else {
        @include pricesInBox();
    }
    margin: 0;
}

.basket-summary__field__value {
    @mixin pricesPinnedToBottom {
        font-weight: $typography-weight-bold;
        text-align: center;
    }

    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-up-to-medium() {
            @include pricesPinnedToBottom();
        }
    }
    font-weight: $typography-weight-regular;
    text-align: right;
}

.basket-summary__total {
    @mixin pricesInBox {
        @include padding(rem(10) 0);
        @include text-large();
        margin-top: $spacing-values-xs;

        .basket-summary__field {
            color: $basket-summary-total-color;
            font-weight: $typography-weight-bold;
        }
    }

    @if ($basket-summary-mobile-pin-prices-to-bottom) {
        @include screen-medium-up() {
            @include pricesInBox();
        }
    } @else {
        @include pricesInBox();
    }
}

.basket-summary__threshold-label {
    display: flex;
    flex-direction: row;
    font-size: $typography-small-font-size;
    justify-content: space-around;
    margin-bottom: rem(2);
    width: 100%;
}

.item-promos-savings {
    .basket-summary__field__value,
    .checkout-summary__field__value {
        color: $basket-summary-item-promos-savings-color;
    }
}

/* ----------------Existing delivery-------------- */
.basket-existing-delivery {
    @include margin(rem(15) 0 0 0);
    @include padding(rem(15));
    background-color: $basket-summary-background-lg;
    border: $panel-border-width solid color($color-gray, 200);
    border-radius: $global-border-radius;
    color: $color-charcoal;
    line-height: 1.4;
}

.basket-existing-delivery__title {
    @include margin(0 0 rem(10));
    @include text-large();
    font-weight: $typography-weight-bold;
}

.basket-existing-delivery__icon {
    @include margin(0 rem(5) 0 0);
    fill: #afb519;
    height: 25px;
    vertical-align: middle;
    width: 25px;
}

.basket-existing-delivery__info {
    @include margin(0 0 rem(10) 0);

    strong {
        @include text-decoration-color($color-charcoal);
    }
}

.basket-banner__delivery-icon {
    margin-left: auto;
    margin-right: auto;
}

.basket-banner__table {
    border: 3px solid #404040;
    border-right: 0;
    display: table;
    font-family: $typography-font-family;
    font-weight: $typography-weight-bold;
    height: 100%;
    line-height: 1.1;
    table-layout: fixed;
    width: 100%;

    @include screen-extra-small-up {
        border-bottom: 3px solid #404040;
    }
}

.basket-banner__delivery-column {
    border-bottom: 0;
    border-right: 3px solid #404040;
    display: table-cell;
    min-height: 80px;
    vertical-align: middle;
}

.basket-banner__delivery-left-column {
    background-color: #f5da83;
    color: #4c230a;
}

.basket-banner__delivery-middle-column {
    background-color: #d9f487;
    color: #405312;
}

.basket-banner__delivery-right-column {
    background-color: #bcd6ed;
    color: #102f96;
}

.basket-banner__row {
    display: table-row;
    width: 100%;
}

.basket-banner__cell {
    display: flex;
}

.basket-banner__header {
    font-family: 'Trade Gothic', Helvetica, Arial, sans-serif;
}

.basket-banner__description-font {
    font-size: 16px;
}

.basket-banner--show-on-desktop {
    display: none;
    @include screen-extra-small-up {
        display: block;
    }
}

.basket-banner--show-on-mobile {
    display: block;
    @include screen-extra-small-up {
        display: none;
    }
}

.basket-banner__mobile-border-bottom {
    border-bottom: 3px solid #404040;
}
