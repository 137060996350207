@mixin modal-reset-size {
    border-radius: rem(5px);
    box-shadow: $modal-shadow;
    max-height: 95%;

    @include size(auto);
}

.modal {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-100;
}

.modal__body {
    background: $modal-background;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: rem(20px) rem(25px);
    position: relative;
    width: 100%;
    z-index: $z-index-modal;
}

.modal__body--fullscreen-xs {
    @include screen-extra-small-up() {
        @include modal-reset-size;
    }
}

.modal__body--fullscreen-sm {
    @include screen-small-up() {
        @include modal-reset-size;
    }
}

.modal__body--fullscreen-md {
    @include screen-medium-up() {
        @include modal-reset-size;
    }
}

.modal__body--fullscreen-lg {
    @include screen-large() {
        @include modal-reset-size;
    }
}

.modal__horizontal-line {
    background: $modal-horizontal-line-color;
    display: block;
    height: rem(1px);
    margin: rem(15px) 0;
    width: 100%;
}

.modal__error {
    color: $modal-error-message-color;
    display: none;
}

.modal__header {
    margin-bottom: rem(20px);
}

.modal__header-title {
    align-items: center;
    display: flex;
}

.modal__header-title-icon {
    height: 40px;
    margin-right: $spacing-values-xs;
    width: 40px;
}

.modal__content {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    margin-bottom: rem(5px);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.modal__footer {
    margin-top: auto;
}

.modal__button--close {
    position: absolute;
    right: rem(10px);
    top: rem(10px);
}

.modal--opened {
    overflow: hidden;
}

.modal__body-wide-buttons {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;

    > a,
    button {
        margin: 0 10px;
        width: 100%;
    }
}
