.util-overflow-auto {
    overflow: auto;
}

.util-overflow-hidden {
    overflow: hidden;
}

.util-overflow-visible {
    overflow: visible;
}

.util-overflow-y-auto {
    overflow-y: auto;
}

.util-overflow-y-hidden {
    overflow-y: hidden;
}

.util-overflow-y-visible {
    overflow-y: visible;
}

.util-overflow-x-auto {
    overflow-x: auto;
}

.util-overflow-x-hidden {
    overflow-x: hidden;
}

.util-overflow-x-visible {
    overflow-x: visible;
}
