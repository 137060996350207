
.drawer__container {
    @include position(fixed, 0 0 0 0);
    z-index: 1300;
}

.drawer {
    @include position(absolute, 0 0 null null);
    @include size(100% 100%);
    background-color: $color-white;
    outline: none;
    z-index: 101;
    @include screen-small-up {
        width: rem(425px);
    }
}

.drawer__content {
    padding: $spacing-values-xs 0;
}

.drawer__content-wrapper {
    height: 100%;
    overflow-y: auto;
    padding: 0 $spacing-values-xs;
}

.drawer__close {
    @include position(absolute, $spacing-values-xs $spacing-values-xs null null);
    @include size(rem(32px));
    // http://stackoverflow.com/a/10170170
    @include transform(translate3d(0,0,0));
    cursor: pointer;
    fill: $color-gray;
    z-index: 5;

    @include screen-small-up {
        fill: $color-white;
        left: rem(-45px);
        right: auto;
    }
}

.drawer__title {
    margin: 0 rem(32px) + $spacing-values-xs 0 0;

    @include screen-small-up {
        margin-right: 0;
    }
}
