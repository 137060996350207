.delivery-tracing-map {
    border-bottom: rem(1px) solid $color-lightgray;
    border-top: rem(1px) solid $color-lightgray;
    height: rem(270px);
    width: 100%;

    .eta {
        color: $color-gray;
    }
}
