@import 'settings.colors';
@import 'settings.global';

@each $name, $color in $color-delivery-status-map {
    .badge__status-#{$name} {
        background-color: $color;
        border-radius: rem(10px);
        color: $color-white;
        display: inline-block;
        font-weight: 700;
        line-height: 1;
        min-width: rem(10px);
        padding: rem(3px) rem(7px);
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
    }
}
