$border: solid rem(1px) $panel-border-color;

.store-locator {
    border: $border;
    overflow: auto;
    position: relative;

    @include screen-small-up {
        display: flex;
        height: $locator-height-desktop-collapsed;
    }

    &.store-locator--standalone {
        border: 0;

        @include screen-small-up {
            flex-grow: 2;
            min-height: $locator-height-desktop;
        }
    }
}

.store-locator--initialised {
    border: 0;
    min-height: $locator-height-desktop-expanded;

    @include screen-small-up {
        border: $border;
    }

    .modal__body & {
        flex: 1 auto;
        min-height: 0;
    }
}

.store-locator__overlay {
    align-items: center;
    background: $backdrop-light-inverted-background;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: $z-index-1;

    @include size(100%);
}

.store-locator__search-form {
    padding: $spacing-values-xs;

    .store-locator__search-panel--initialised & {
        border-bottom: $border;
    }
}

.store-locator__search-panel {
    background: $modal-background;
    border: $border;
    width: rem(300px);

    &.store-locator__search-panel--initialised {
        border-width: rem(1px) 0 0 0;
        width: 100%;

        @include screen-small-up {
            border-width: 0 rem(1px) 0 0;
            width: rem(300px);
        }
    }

    @include screen-small-up {
        position: absolute;
        width: rem(300px);
    }

    .store-locator--standalone & {
        width: 100%;
        @include screen-small-up {
            width: rem(400px);
        }
    }
}

.store-locator__search-panel--initialised {
    position: static;

    @include screen-small-up {
        display: flex;
        flex-direction: column;
    }
}

.store-locator__search-results {
    height: auto;

    @include screen-small-up {
        flex: 1;
        min-height: 0;
        overflow: auto;
        position: relative;
    }
}

.store-locator-search__result-row {
    background: $modal-background;
    border-bottom: $border;
    cursor: pointer;
    padding: $spacing-values-xs;

    &:last-child {
        border-bottom: none;
    }
}

.store-locator-search__result-row--selected {
    background: $panel-light-fill-background-color;
    cursor: auto;
}

.store-locator__map-column {
    width: 100%;

    @include screen-small-up {
        flex-grow: 2;
        order: 2;
        width: auto;
    }
}

.store-locator__map-element,
.store-locator__map-container {
    @include size(100% $locator-height-mobile);
    @include screen-small-up {
        height: 100%;
    }
}

.store-locator__form {
    background: $modal-background;
}

.map__marker {
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    fill: $button-action-background-color;
    position: relative;
}

.map__marker--active {
    fill: $button-primary-background-color;
}

.map-marker {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.map-marker__icon {
    fill: $button-action-background-color;
}

.map-marker__icon--active {
    fill: $button-primary-background-color;
}

.map-marker__label {
    color: $color-white;
    font-size: rem(14px);
    position: absolute;
    text-align: center;
    top: rem(5px);
    width: 100%;
}

.store-card__address {
    color: $shop-details-color;
}

.store-card__hours {
    color: $shop-details-color;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    font-size: $typography-small-font-size;
    height: ($typography-small-font-size + $store-card-hours-row-padding) * 5;
}

.store-card-hours__row {
    padding: 0 $store-card-hours-row-padding $store-card-hours-row-padding $store-card-hours-row-padding;
}
