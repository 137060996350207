.o-absolute {
    position: absolute;
}

.o-absolute__top-left {
    left: 0;
    top: 0;
}

.o-absolute__top-right {
    right: 0;
    top: 0;
}

.o-absolute__bottom-left {
    bottom: 0;
    left: 0;
}

.o-absolute__bottom-right {
    bottom: 0;
    right: 0;
}
