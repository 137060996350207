.social-bar {
    background: $social-bar-background;
}

.social-bar__items {
    margin: 0 auto;
    padding: $spacing-values-xs 0;
    text-align: center;
}

.social-bar__item {
    display: inline-block;
    margin-right: $spacing-values-xxs;
    vertical-align: middle;
}

.social-bar__link {
    background: $social-bar-item-background;
    border-radius: $social-bar-border-radius;
    display: block;
    line-height: normal;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
}

.social-bar__icon {
    fill: $social-bar-item-color;
    height: $social-bar-icon-height;
    padding: rem(8px);
    width: $social-bar-icon-width;
}

.social-bar__hashtag {
    background: $social-bar-item-background;
    color: $social-bar-hashtag-color;
    display: block;
    font-family: $social-bar-font;
    height: $social-bar-icon-height;
    line-height: $social-bar-icon-height;
    padding: 0 $spacing-values-xxs;
    -moz-user-select: all;
    -ms-user-select: all;
    -webkit-user-select: all;
    user-select: all;
}

.social-bar__link:hover {
    background: $social-bar-item-background-hover;

    > .social-bar__icon {
        fill: $social-bar-item-color-hover;
    }
}
