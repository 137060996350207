.age-restricted-modal__title {
    margin: $spacing-values-sm 0;
}

.age-restricted-modal__info {
    color: $color-primary;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: $spacing-values-xxs;
}

.age-restricted-modal__terms {
    display: block;
    font-size: 12px;
    margin: $spacing-values-md 0 $spacing-values-xxs;
}

.age-restricted-modal__error {
    background-color: #fcf7da;
    max-width: 250px;
    padding: $spacing-values-xxs;

    @include screen-up-to-small {
        margin-bottom: $spacing-values-xxs;
    }
}

.age-restricted-modal__footer {
    @include screen-small-up {
        align-items: center;
        display: flex;
    }
}

.age-restricted-modal__button-group {
    margin-left: auto;
}
