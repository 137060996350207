.breadcrumb {
    background-color: $breadcrumb-background-color;
}

.breadcrumb__item {
    color: $breadcrumb-item-link-color;
    display: inline-block;
    font-weight: $typography-weight-regular;
    padding: rem(15px);
}

.breadcrumb__item--text {
    color: $breadcrumb-item-text-color;

    &:first-child {
        padding-left: 0;
    }
}

.breadcrumb__item--active {
    background-color: $breadcrumb-item-active-background-color;
    color: $breadcrumb-item-active-text-color;
    font-weight: $breadcrumb-item-active-font-weight;

    .icon {
        fill: $breadcrumb-item-active-text-color;
        margin: 0 0 0 rem(5px);
    }
}
