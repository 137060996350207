.pull {
    @include clearfix;
}

.pull__left {
    float: left;
}

.pull__right {
    float: right;
}
