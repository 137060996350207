.tabs {
    border-bottom: $tab-inactive-border;
    margin: 0 0 $global-element-margin-base;
    padding: 0;

    &.tabs--top-border {
        border-bottom: none;
    }

    &.tabs--even-width {
        display: flex;
        flex-direction: row;
    }
}

.tabs--block {
    border-bottom: none;
    margin: 0;
}

.tabs--centered {
    text-align: center;
}

.tabs--large {
    font-size: $heading-4-font-size;

    @include screen-small-up {
        font-size: $heading-3-font-size;
    }
}

.tabs--small {
    font-size: rem(13px);
}

.tabs--fill {
    display: table;
    width: 100%;
}

.tabs__tab {
    color: $color-gray;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 rem(-1px);

    &:hover {
        color: $tab-hover-color; //$color-charcoal;
    }

    .tabs--block & {
        border-bottom: $tab-inactive-border;
        display: block;

        & .tabs__link {
            padding: $spacing-values-xs;
        }
    }

    .tabs--fill & {
        display: table-cell;
        text-align: center;
    }

    .tabs--even-width & {
        flex: 1;
        text-align: center;
    }

    .tabs--top-border & {
        border-bottom: $tab-inactive-border;
    }
}

.tabs__tab--active {
    border-bottom: $tab-active-border-color 1px solid;
    color: $color-charcoal;
    cursor: default;

    &:hover {
        color: $tab-active-hover-color;
    }

    .tabs--block & {
        border-bottom: $tab-inactive-border;
    }

    .tabs--fill & {
        @include position(relative, 1px null null null);
    }

    .tabs__link {
        font-weight: $tab-active-font-weight;
    }

    .tabs--top-border & {
        border: $tab-inactive-border;
        border-bottom: none;
        border-top: $tab-active-border-color 1px solid;
    }
}

.tabs__link {
    color: inherit;
    cursor: inherit;
    display: block;
    font-weight: $tab-font-weight;
    padding: $spacing-values-xxs;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
