h1,
h2,
h3,
h4,
h5,
h6 {
    .heading__description {
        color: $typography-heading-description-color;
    }
    margin: 0;
}

h1 {
    @include heading-1;

    .heading__description {
        @include heading-4;
    }
}

h2 {
    @include heading-2;

    .heading__description {
        @include heading-5;
    }
}

h3 {
    @include heading-3;

    .heading__description {
        @include heading-6;
    }
}

h4 {
    @include heading-4;

    .heading__description {
        @include text-base;
    }
}

h5 {
    @include heading-5;

    .heading__description {
        @include text-small;
    }
}

h6 {
    @include heading-6;
}
