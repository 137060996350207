.icon {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
}

.icon--middle {
    vertical-align: middle;
}

.icon--lightest {
    color: $color-white;
}

.icon--lighter {
    color: color($color-gray, 100);
}

.icon--light {
    color: color($color-gray, 200);
}

.icon--darkest {
    color: $color-black;
}
