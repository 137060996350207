.postcode-checker {
    @include screen-small-up {
        width: rem(350px);
    }
    overflow: auto;
}

.postcode-checker__content {
    display: flex;
    flex-direction: column;
}

.postcode-checker__text {
    font-weight: normal;
    text-align: center;
}

.postcode-checker__browse-shop {
    color: $color-gray;
    cursor: pointer;
    font-size: rem(15px);
}
