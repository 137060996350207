@mixin screen-extra-small {
    @media (min-width: map-get($grid-breakpoint-widths, xs)) and (max-width: (map-get($grid-breakpoint-widths, sm) - 1)) {
        @content;
    }
}

@mixin screen-extra-small-up {
    @media (min-width: map-get($grid-breakpoint-widths, xs)) {
        @content;
    }
}

@mixin screen-small {
    @media (min-width: map-get($grid-breakpoint-widths, sm)) and (max-width: (map-get($grid-breakpoint-widths, md) - 1)) {
        @content;
    }
}

@mixin screen-small-up {
    @media (min-width: map-get($grid-breakpoint-widths, sm)) {
        @content;
    }
}

@mixin screen-medium {
    @media (min-width: map-get($grid-breakpoint-widths, md)) and (max-width: (map-get($grid-breakpoint-widths, lg) - 1)) {
        @content;
    }
}

@mixin screen-medium-up {
    @media (min-width: map-get($grid-breakpoint-widths, md)) {
        @content;
    }
}

@mixin screen-large {
    @media (min-width: map-get($grid-breakpoint-widths, lg)) {
        @content;
    }
}

@mixin screen-up-to-medium {
    @media (max-width: (map-get($grid-breakpoint-widths, md) - 1)) {
        @content;
    }
}

@mixin screen-up-to-small {
    @media (max-width: (map-get($grid-breakpoint-widths, sm) - 1)) {
        @content;
    }
}

@mixin screen-up-to-extra-small {
    @media (max-width: (map-get($grid-breakpoint-widths, xxs) - 1)) {
        @content;
    }
}
