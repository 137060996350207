@mixin dropdown-link-active {
    background-color: $dropdown-link-text-hover-color;
    text-decoration: none;
}

.dropdown {
    background-color: $dropdown-background-color;
    border: $dropdown-border;
    border-color: $dropdown-border-color;
    box-shadow: $dropdown-box-shadow;
    display: inline-block;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: rem(5px) 0;
    z-index: $z-index-1--dropdown;
}

.dropdown--minimal {
    border: 0;
    width: 100%;
}

.dropdown__link--active {
    @include dropdown-link-active;
}

.dropdown__link {
    color: $dropdown-link-color;
    cursor: pointer;
    display: block;
    font-weight: $dropdown-font-weight;
    letter-spacing: 0;
    padding: rem(10px) rem(15px);
    text-transform: none;

    &:hover {
        @include dropdown-link-active;
    }
}

.dropdown__link--default {
    color: $anchor-color;
    font-weight: $typography-weight-bold;
    margin: rem(15px) rem(15px) rem(10px);
    padding: 0;

    &:hover {
        background-color: transparent;
        text-decoration: underline;
    }
}

.dropdown__separator {
    background-color: $dropdown-separator-background-color;
    height: rem(1px);
    margin: rem(5px) 0;
}

