.alert {
    border-radius: $global-border-radius;
    font-weight: $alert-font-weight;
    margin: 0 0 $global-element-margin-base;
    padding: rem(12px);

    .icon {
        margin: 0 rem(10px) 0 0;
    }
}

.alert--success {
    @include alert-colors($alert-success-background-color, $alert-success-text-color);

    &.alert--light {
        color: $alert-success-light-text-color;
    }

    &.alert--strong {
        background-color: $alert-success-strong-background-color;
    }
}

.alert--error {
    @include alert-colors($alert-error-background-color, $alert-error-text-color);

    &.alert--light {
        color: $alert-error-light-text-color;
    }

    &.alert--strong {
        background-color: $alert-error-strong-background-color;
    }
}

.alert--warning {
    @include alert-colors($alert-warning-background-color, $alert-warning-text-color);

    &.alert--light {
        color: $alert-warning-light-text-color;
    }

    &.alert--strong {
        background-color: $alert-warning-strong-background-color;
    }
}

.alert--light {
    background-color: transparent;
    border: rem(1px) solid;
}

.alert--borderless {
    border: none;
}

.alert--strong {
    color: $color-white;
}

.alert--text-centered {
    text-align: center;
}
