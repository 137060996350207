@mixin make-initial-columns {
    @for $i from 1 through 12 {
        .col-xxs-#{$i} {
            @include span-columns($i);
            min-height: rem(1px);
        }
    }
}

@mixin make-initial-offsets {
    @for $i from 1 through 12 {
        .col-xxs-offset-#{$i} {
            @include shift($i);
        }

        .col-xxs-negative-offset-#{$i} {
            @include shift(-$i);
        }
    }
}

@mixin make-breakpoint-columns {
    @each $key, $value in $grid-breakpoints {
        @for $i from 1 through 12 {
            @include media($value) {
                .col-#{$key}-#{$i} {
                    @include span-columns($i);
                    min-height: rem(1px);
                }
            }
        }
    }
}

@mixin make-breakpoint-offsets {
    @each $key, $value in $grid-breakpoints {
        @for $i from 1 through 12 {
            @include media($value) {
                .col-#{$key}-offset-#{$i} {
                    @include shift($i);
                }

                .col-#{$key}-negative-offset-#{$i} {
                    @include shift(-$i);
                }
            }
        }
    }
}

@mixin make-hidden-classes {
    $breakpoint-keys: to-list($grid-breakpoint-widths, 'keys');
    $breakpoint-values: to-list($grid-breakpoint-widths, 'values');

    .hidden-xxs {
        @media (max-width: map-get($grid-breakpoint-widths, xs) - 1) {
            display: none !important;
        }
    }

    @each $breakpoint in $breakpoint-keys {
        $final-breakpoint: nth($breakpoint-keys, length($breakpoint-keys));
        $min-breakpoint-width: nth($breakpoint-values, index($breakpoint-keys, $breakpoint));

        @if $breakpoint != $final-breakpoint {
            $max-breakpoint-width: nth($breakpoint-values, index($breakpoint-keys, $breakpoint) + 1) - 1;

            @media (min-width: $min-breakpoint-width) and (max-width: $max-breakpoint-width) {
                .hidden-#{$breakpoint} {
                    display: none !important;
                }
            }
        } @else {
            @media (min-width: $min-breakpoint-width) {
                .hidden-#{$breakpoint} {
                    display: none !important;
                }
            }
        }
    }
}

@mixin make-visible-x-block-classes {
    $breakpoint-keys: to-list($grid-breakpoint-widths, 'keys');
    $breakpoint-values: to-list($grid-breakpoint-widths, 'values');

    .visible-xxs-block {
        @media (max-width: map-get($grid-breakpoint-widths, xs) - 1) {
            display: block !important;
        }
    }

    @each $breakpoint in $breakpoint-keys {
        $final-breakpoint: nth($breakpoint-keys, length($breakpoint-keys));
        $min-breakpoint-width: nth($breakpoint-values, index($breakpoint-keys, $breakpoint));

        @if $breakpoint != $final-breakpoint {
            $max-breakpoint-width: nth($breakpoint-values, index($breakpoint-keys, $breakpoint) + 1) - 1;

            @media (min-width: $min-breakpoint-width) and (max-width: $max-breakpoint-width) {
                .visible-#{$breakpoint}-block {
                    display: block !important;
                }
            }
        } @else {
            @media (min-width: $min-breakpoint-width) {
                .visible-#{$breakpoint}-block {
                    display: block !important;
                }
            }
        }
    }
}

@mixin make-visible-x-inline-block-classes {
    $breakpoint-keys: to-list($grid-breakpoint-widths, 'keys');
    $breakpoint-values: to-list($grid-breakpoint-widths, 'values');

    .visible-xxs-inline-block {
        @media (max-width: map-get($grid-breakpoint-widths, xs) - 1) {
            display: inline-block !important;
        }
    }

    @each $breakpoint in $breakpoint-keys {
        $final-breakpoint: nth($breakpoint-keys, length($breakpoint-keys));
        $min-breakpoint-width: nth($breakpoint-values, index($breakpoint-keys, $breakpoint));

        @if $breakpoint != $final-breakpoint {
            $max-breakpoint-width: nth($breakpoint-values, index($breakpoint-keys, $breakpoint) + 1) - 1;

            @media (min-width: $min-breakpoint-width) and (max-width: $max-breakpoint-width) {
                .visible-#{$breakpoint}-inline-block {
                    display: inline-block !important;
                }
            }
        } @else {
            @media (min-width: $min-breakpoint-width) {
                .visible-#{$breakpoint}-inline-block {
                    display: inline-block !important;
                }
            }
        }
    }
}
