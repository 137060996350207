ul,
ol,
dl,
dd {
    margin: 0;
}

dl {
    @include clearfix;
    line-height: 2;
}

dt {
    color: $list-definition-color;
    float: left;
    margin: 0 rem(5px) 0 0;
}
