$header-logo-wrapper-large-margin: 0 !default;

$header-logo-small-height: rem(40px) !default;
$header-logo-small-width: $header-logo-small-height * 2.45 !default;

$header-logo-large-height: rem(120px) !default;
$header-logo-large-width: $header-logo-large-height * 1.8 !default;
$header-logo-large-margin: rem(-25px) 0 !default;

$header-logo-medium-height: $header-logo-large-height !default;
$header-logo-medium-width: $header-logo-large-width !default;
$header-logo-medium-margin: $header-logo-large-margin !default;

$intersite-box-color: color($color-gray, 100) !default;
$intersite-box-border: rem(1px) !default;
$intersite-box-shadow: 0 $intersite-box-border $intersite-box-border 0 $intersite-box-color !default;

$header-background-small: $color-white !default;
$header-background-large: $header-background-small !default;

$header-menu-button-background: $button-primary-background-color !default;

$header-logo-container-large-width: auto !default;
$header-logo-large-z-index: $z-index-1 !default;
$header-logo-menu-medium-screen-display: none !default;

$header-menu-account-regular-medium-display: block !default;

$header-menu-account-large-medium-display: none !default;
$header-menu-account-large-color: $color-white !default;
$header-menu-account-large-font-size: rem(11px) !default;

$header-menu-large-padding: $spacing-values-sm 0 0 0 !default;
$header-menu-medium-padding: 0 !default;
$header-search-medium-padding: 0 $spacing-values-xxs 0 0 !default;
$header-search-large-padding: $spacing-values-sm $spacing-values-xxs 0 0 !default;
$header-search-input-border-color: $form-input-border-color !default;
$header-search-input-background-color: $form-input-background-color !default;

$header-logo-menu-large-padding-top: 0 !default;

$header-basket-button-border-color: $button-action-border-color !default;

$header-favorites-large-display: none !default;

$intersite-terms-text-color: color($color-black, 200) !default;
$intersite-background-color: $color-white !default;
$intersite-z-index: $header-logo-large-z-index + 1 !default;

$header-container-box-shadow: none !default;
