.media,
.media__right {
    overflow: hidden;
}

.media__left {
    @include media-base-values();

    .media--stacked-xxs > &,
    .media--stacked-xs > &,
    .media--stacked-sm > &,
    .media--stacked-md > & {
        float: none;
        margin: 0 0 rem(15px);
    }

    .media--stacked-xxs > & {
        @include screen-extra-small-up {
            @include media-base-values();
        }
    }

    .media--stacked-xs > & {
        @include screen-small-up {
            @include media-base-values();
        }
    }

    .media--stacked-sm > & {
        @include screen-medium-up {
            @include media-base-values();
        }
    }

    .media--stacked-md > & {
        @include screen-large {
            @include media-base-values();
        }
    }
}

.media--vertical-align {
    align-items: center;
    display: flex;

    & .media__right {
        overflow: visible;
    }
}

.media__expanded {
    flex-grow: 2;
}

.media__secondary-text {
    color: $list-definition-color;
}
