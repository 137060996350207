.sample-selector__image,
.sample-selector__image-placeholder {
    background-color: $color-lightgray;
    display: block;
    flex-shrink: 0;
    height: 120px;
    margin-bottom: $spacing-values-xxxs;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}

.sample-selector__product {
    border-color: transparent;
    border-style: solid;
    border-width: 2px 0;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 240px;
    padding: $spacing-values-xxs;
    position: relative;
    transition: border .175s;
    width: 140px;

    &:hover {
        border-color: $sample-selector-product-hover-color;
    }
}

.sample-selector__product--disabled {
    opacity: .5;
}

.sample-selector__product-name {
    display: block;
    flex-grow: 0;
    overflow: hidden;
}

.sample-selector__checkbox {
    margin-bottom: $spacing-values-xxs;
    text-align: center;
}

.sample-selector__spinner {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
