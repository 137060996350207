.carousel {
    position: relative;

    .slick-list {
        margin: 0 !important;
    }

    .slick-track {
        margin: 0;
    }
}

.carousel__prev,
.carousel__next {
    background-color: rgba($color-white, .8);
    border: none;
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    transition: background .175s;
    width: 40px;
    z-index: 1;

    &:hover {
        background-color: rgba($color-lightgray, .8);
    }

    svg {
        height: 25px;
        width: 25px;
    }
}

.carousel__prev {
    left: 0;
}

.carousel__next {
    right: 0;
}

.banner-carousel__dots {
    bottom: 20px;
    display: block;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;

    .slick-list {
        margin: 0 !important;
    }

    .slick-track {
        margin: 0;
    }

    li {
        display: inline-block;
    }

    .slick-active > button {
        background: rgba(255, 255, 255, 1);

        &:hover {
            background: rgba(255, 255, 255, 1);
        }
    }

    li > button {
        background: rgba(250, 250, 250, .5);
        border: 0;
        border-radius: 100%;
        cursor: pointer;
        font-size: 0;
        height: 15px;
        margin: 0 2.5px;
        outline-style: none !important;
        padding: 0;
        width: 15px;
        z-index: 2;

        &:hover {
            background: rgba(250, 250, 250, .75);
        }
    }
}

.banner-carousel__prev,
.banner-carousel__next {
    background: transparent;
    border-color: #fafafa;
    border-style: solid;
    border-width: 5px 5px 0 0;
    bottom: 150px;
    cursor: pointer;
    display: block;
    height: 30px;
    margin-top: -15px;
    opacity: .1;
    outline: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity .9s;
    transition: opacity .9s;
    width: 30px;
    z-index: 3;

    &:hover,
    &:visited {
        border-color: #fff;
        opacity: 1;
        -webkit-transition: opacity .9s;
        transition: opacity .9s;
    }

    &:focus {
        outline-style: none !important;
    }
}

.banner-carousel__prev {
    left: 30px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.banner-carousel__next {
    right: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
