$form-checkmark-base64-url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCA0MSAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Y2hlY2s8L3RpdGxlPjxwYXRoIGQ9Ik0zNi44LjNMMTUuNSAyMS41IDQuMiAxMC4yIDAgMTQuNCAxNS41IDMwbDIuMi0yLjEgMi4xLTIuMUw0MSA0LjUgMzYuOC4zeiIgZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsPSIjZmZmZmZmIiAvPjwvc3ZnPg==' !default;

$form-label-error-color: $color-red !default;
$form-label-text-color: $typography-text-color !default;
$form-label-font-weight: $typography-weight-regular !default;

$form-border-width: rem(1px) !default;

$form-input-background-color: color($color-gray, 50) !default;
$form-input-border-color: color($color-gray, 200) !default;
$form-input-focus-border-color: $color-charcoal !default;
$form-input-height: $global-element-height-base !default;
$form-input-width: 100% !default;
$form-input-width-maxlength-1: $form-input-height !default;
$form-input-width-maxlength-2: rem(48px) !default;
$form-input-width-maxlength-3: rem(56px) !default;
$form-input-padding: rem(10px) !default;
$form-input-color: initial !default;
$form-input-error-border-color: $form-label-error-color !default;

$form-icon-highlighted-background-color: $color-charcoal !default;
$form-icon-highlighted-border-color: $color-charcoal !default;

$form-radio-checked-background-color: $color-white !default;
$form-radio-checked-border-color: $color-charcoal !default;
$form-radio-background-color: $color-white !default;
$form-radio-border-color: $color-gray !default;

$form-checkbox-checked-background-color: $color-gray !default;
$form-checkbox-checked-border-color: $color-charcoal !default;
$form-checkbox-background-color: $color-white !default;
$form-checkbox-border-color: $color-gray !default;

$form-select-dropdown-icon-color: $color-charcoal !default;
