.backdrop {
    background: $backdrop-background;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-backdrop;
}

.backdrop--inverted {
    background: $backdrop-inverted-background;
}

.backdrop--fixed {
    position: fixed;
}

.backdrop__content {
    position: relative;
    z-index: $z-index-backdrop-content;
}

.backdrop--transparent {
    background: transparent;
}
