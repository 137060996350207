.util-list-inline {
    line-height: $typography-line-height !important;
    padding-left: 0 !important;

    > dt,
    > dd,
    > li {
        display: inline-block !important;
    }

    > li {
        margin-right: rem(20px);
    }

    > dt {
        float: none !important;
    }

    > dd {
        margin-right: rem(20px) !important;
    }
}
