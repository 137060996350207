form {
    margin: 0;
}

label {
    color: $form-label-text-color;
}

button {
    @include button-base;

    &[disabled] {
        @include utility-disabled-styles;
    }
}
