.icon-badge {
    @include size($global-element-height-base);
    background-color: $color-white;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    text-align: center;

    & + & {
        margin: 0 0 0 rem(10px);
    }
}

.icon-badge__icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
