$color-dragonfly: #0078a6;
$color-apple: #acb51b;
$color-raspberry: #c5003e;

$color-gray: #9e9e9e;
$color-white: #fff;
$color-black: #000;
$color-red: $color-raspberry;

$color-primary: $color-apple;
$color-primary-contrast: $color-white;

$color-secondary: color($color-gray, 50);
$color-secondary-contrast: color($color-dragonfly, 900);

$color-action: $color-dragonfly;
$color-action-contrast: $color-white;

$color-info: $color-dragonfly;
$color-success: $color-apple;
$color-error: #e91e63;

$color-delivery-status-map: (
    placed: #43a047,
    on-time: #43a047,
    late: #fb8c00,
    delivered: #43a047,
    cancelled: #b71c1c
);
