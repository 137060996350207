$color-red: #f00 !default;
$color-pink: #e91e63 !default;
$color-amber: #ffbf00 !default;
$color-green: #8db600 !default;
$color-charcoal: #333 !default;
$color-gray: #9e9e9e !default;
$color-lightgray: #e2e2e2 !default;
$color-darkgray: #b9b9b9 !default;
$color-white: #fff !default;
$color-black: #000 !default;
$color-paypal: #009fe3 !default;

$color-primary: $color-green !default;
$color-secondary: $color-charcoal !default;

$color-info: $color-paypal !default;
$color-info-contrast: $color-white !default;
$color-success: $color-green !default;
$color-success-contrast: $color-white !default;
$color-error: $color-red !default;
$color-error-contrast: $color-white !default;
