table {
    font-size: 1rem;
    width: 100%;
}

tr {
    border: $table-border-color solid;
}

thead tr {
    border-width: rem(1px) 0 rem(2px);
}

tbody tr {
    border-width: rem(1px) 0;

    &:last-child {
        border: none;
    }
}

th,
td {
    padding: rem(15px);
}

th {
    @include heading-6;
    text-align: left;
}
