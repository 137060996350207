.create-address-modal {
    @include screen-small-up {
        width: rem(450px);
    }
}

.create-address__labelComment {
    display: inline-block;
    float: right;
}
