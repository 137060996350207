$button-text-case: uppercase;

$button-primary-background-color: $color-primary;
$button-primary-background-color-alternate: color($color-primary, 700);
$button-primary-text-color: $color-primary-contrast;
$button-primary-light-border-color: $color-primary;
$button-primary-light-text-color: $color-secondary-contrast;
$button-primary-border-color: transparent;

$button-secondary-background-color: $color-secondary;
$button-secondary-background-color-alternate: color($color-secondary, 600);
$button-secondary-border-color: $button-secondary-background-color-alternate;
$button-secondary-text-color: $color-secondary-contrast;
$button-secondary-light-border-color: $color-secondary;
$button-secondary-light-text-color: $color-secondary-contrast;

$button-action-background-color: $color-action;
$button-action-background-color-alternate: color($color-action, 700);
$button-action-border-color: transparent;
$button-action-text-color: $color-action-contrast;
$button-action-light-border-color: $color-action;
$button-action-light-text-color: $color-secondary-contrast;

