.promotion-gift__text {
    color: $promotion-free-gift-color;
}

.promotion-gift__badge {
    background-color: $promotion-free-gift-color;
    color: $color-white;
    font-size: $typography-small-font-size;
}

.promotion-gift__bop-child:before {
    background-color: $promotion-free-gift-color;
    z-index: $z-index-1;
}

.promotion-discount__block {
    background-color: $promotion-discount-block-background;
    color: $promotion-discount-block-color;

    dt {
        color: $promotion-discount-block-color;
    }
}
