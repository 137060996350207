.util-display-none {
    display: none !important;
}

.util-display-block {
    display: block !important;
}

.util-display-inline-block {
    display: inline-block !important;
}

.util-display-inline {
    display: inline !important;
}

.util-display-flex {
    display: flex;
}

.util-center-vertically {
    align-items: center !important;
    display: flex;
}

.util-center-both {
    align-items: center !important;
    display: flex;
    justify-content: center !important;
}

.util-justify-content-space-between {
    justify-content: space-between !important;
}

.util-flex-direction-row {
    flex-direction: row !important;
}

.util-flex-direction-column {
    flex-direction: column !important;
}

.util-flex-align-right {
    justify-content: flex-end !important;
}

.util-position-relative {
    position: relative !important;
}
