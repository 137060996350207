.spinner {
    @include size($spinner-size);
    @include animation(spin $spinner-speed infinite linear);
    @include hide-text();
    border: $spinner-thickness solid $spinner-background-color;
    border-radius: 50%;
    border-right-color: $spinner-foreground-color;
    border-top-color: $spinner-foreground-color;
    display: inline-block;
}

.spinner--alternative {
    border-right-color: $spinner-alternative-foreground-color;
    border-top-color: $spinner-alternative-foreground-color;
}

.spinner--small {
    @include size($spinner-small-size);
}
