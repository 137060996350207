@import 'settings.modal';

$panel-border-width: rem(1px) !default;
$panel-border-color: color($color-gray, 200) !default;

$panel-background-color: $modal-background !default;

$panel-light-fill-background-color: color($color-gray, 100) !default;
$panel-light-border-color: color($color-gray, 100) !default;

$panel-section-fill-background-color: color($color-gray, 50) !default;

$panel-shadow-border-color: $color-lightgray !default;
$panel-shadow-border: rem(1px) solid $panel-shadow-border-color !default;

$panel-section-width: 100%;
$panel-section-split-width: 50%;

$panel-border-radius: $global-border-radius !default;
