.simple-layout {
    background: $simple-layout-background;
    flex-grow: 1;
    min-height: 100%;
    padding-top: $spacing-values-lg;
    width: 100%;
}

.simple-layout__logo {
    margin-bottom: $spacing-values-lg;
    text-align: center;
}

.simple-layout-logo__image {
    @include size($simple-layout-header-logo-small-width $simple-layout-header-logo-small-height);
}

.simple-layout__content {
    background: $color-white;
    border-radius: $global-border-radius;
    box-shadow: $simple-layout-content-shadow;
    padding: $spacing-values-md;
}
