@mixin create-spacing-classes() {
    @each $direction in $spacing-directions {
        @each $property in $spacing-properties {
            @each $size, $value in $spacing-values {
                .util-spacing-#{$property}-#{$direction}-#{$size} {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .util-spacing-#{$property}-#{$direction}-none {
                #{$property}-#{$direction}: 0 !important;
            }
        }
    }

    @each $property in $spacing-properties {
        @each $size, $value in $spacing-values {
            .util-spacing-#{$property}-#{$size} {
                #{$property}: $value !important;
            }
        }

        .util-spacing-#{$property}-none {
            #{$property}: 0 !important;
        }
    }
}
