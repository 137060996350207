@mixin button-base {
    @include appearance(none);
    border: rem(1px) solid;
    border-radius: $global-border-radius;
    cursor: pointer;
    display: inline-block;
    font-family: $button-font-family;
    font-weight: $typography-weight-bold;
    height: $button-height;
    letter-spacing: $button-letter-spacing;
    line-height: $button-line-height;
    padding: 0 rem(10px);
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: $button-text-case;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }
}
