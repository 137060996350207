.opening-time-modal {
    max-width: rem(400px);
}

.opening-time-modal__title {
    text-align: center;
}

.opening-time-modal__icon {
    background-color: $color-red;
    border-radius: 50%;
    color: white;
    height: rem(70px);
    margin: $spacing-values-xs auto;
    padding: rem(15px);
    width: rem(70px);

    .icon {
        height: 100%;
        width: 100%;
    }
}

.opening-time-modal__message {
    color: $color-gray;
    font-size: rem(15px);
    margin: $spacing-values-sm auto;
    text-align: center;
}

.opening-time-modal__brand {
    filter: #{'grayscale()'};
    margin: $spacing-values-sm auto;
    margin-top: 0;
    opacity: .2;
    width: rem(70px);
}
