// This is used by the Bourbon REM function
$em-base: 14px;

$border-box-sizing: false !global;

$global-border-radius: 0 !default;

$global-disabled-cursor: not-allowed !default;
$global-disabled-opacity: .5 !default;

$global-element-height-base: rem(40px) !default;
$global-element-margin-base: rem(20px) !default;

$global-transition-speed: .3s !default;
