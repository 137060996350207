.header-container__background {
    background: $header-background-small;
    box-shadow: $header-container-box-shadow;
    flex: none;
    width: 100%;

    @include screen-medium-up() {
        background: $header-background-large;
    }
}

// TODO: remove '--full' modifier once DS' header code starts explicitly using these styles,
// Currently DS uses '.header' class for legacy header which causes styles from UIKit to leak on uikit-powered pages
.header--full {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: map-get($grid-breakpoint-widths, lg);
    padding: $spacing-values-xs;

    @include screen-small() {
        flex-wrap: nowrap;
    }

    @include screen-medium-up() {
        position: relative;
    }
}

.header__logo {
    align-items: center;
    display: inline-flex;
    width: 65%;

    @include screen-small-up() {
        width: auto;
    }

    @include screen-medium-up() {
        width: $header-logo-container-large-width;
    }
}

.header-logo__image {
    @include screen-medium-up() {
        margin: $header-logo-wrapper-large-margin;
    }
}

.header__logo-image--small {
    display: block;
    @include size($header-logo-small-width $header-logo-small-height);
}

.header__logo-image--large {
    @include size($header-logo-medium-width $header-logo-medium-height);
    display: block;
    position: relative;
    z-index: $header-logo-large-z-index;

    @include screen-medium-up() {
        margin: $header-logo-medium-margin;
    }

    @include screen-large() {
        @include size($header-logo-large-width $header-logo-large-height);
        margin: $header-logo-large-margin;
    }

    &.header__logo-image--checkout {
        margin: 0;
    }
}

.header-logo__menu {
    > .button {
        background: $header-menu-button-background;
    }

    @include screen-medium-up() {
        display: $header-logo-menu-medium-screen-display;
    }

    @include screen-large() {
        padding-top: $header-logo-menu-large-padding-top;
    }
}

.header__search {
    flex-grow: 1;
    padding-top: $spacing-values-xxs;

    @include screen-small-up() {
        padding: 0 $spacing-values-xxs 0 $spacing-values-xxs;
    }

    @include screen-medium-up() {
        padding: $header-search-medium-padding;
    }

    @include screen-large() {
        min-width: 50%;
        padding: $header-search-large-padding;
    }

    .header-search__input {
        background-color: $header-search-input-background-color;
        border-color: $header-search-input-border-color;
    }
}

.header__search--active {
    z-index: $z-index-popover;
}

.header-search__suggestions {
    width: 100%;

    @include screen-large {
        width: rem(455px);
    }
}

.header__menu {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 35%;

    @include screen-small-up() {
        order: 2;
        width: auto;
    }

    @include screen-medium-up() {
        padding: $header-menu-medium-padding;
    }

    @include screen-large() {
        padding: $header-menu-large-padding;
    }
}

.header__favorites {
    display: $header-favorites-large-display;

    @include screen-up-to-extra-small() {
        display: none;
    }
}

.header__navbar-menu--active {
    z-index: $z-index-modal;
}

.header__account-menu {
    @include screen-medium-up() {
        display: $header-menu-account-regular-medium-display;
    }

    @include screen-large() {
        display: none;
    }
}

.header__opening-hours-bar {
    background-color: $color-red;
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
}

.header__opening-hours-bar-label {
    color: white;
    font-size: $typography-small-font-size;
    padding: 9px 0 8px;
}

.header_popover__paragraph {
    margin: rem(2px);
}

.header-popover__button {
    margin-bottom: $spacing-values-xxs;
    text-align: center;
    width: 100%;
}

.header-popover__account-logout {
    font-weight: normal;
}

.popover-logged-in__icon {
    float: left;
    margin: $spacing-values-xxs;
}

.header-popover__menu {
    list-style-type: none;
    padding-left: $spacing-values-xs;
}

.header-popover__menu-link {
    color: $color-charcoal;
    font-weight: normal;
    line-height: 1.8;
}

.header-popover__menu--customer-service {
    margin-bottom: $spacing-values-xxs;
    padding: 0;
}

.header-menu__account--large {
    display: none;
    position: absolute;
    right: $spacing-values-xs;
    top: rem(10px);

    @include screen-medium-up() {
        display: $header-menu-account-large-medium-display;
    }

    @include screen-large() {
        display: block;
    }
}

.header-menu-large__item {
    color: $header-menu-account-large-color;
    display: inline-block;
    font-size: $header-menu-account-large-font-size;
    font-weight: $typography-weight-regular;
    margin: 0 0 0 rem(10px);
}

.header-intersite {
    background: $intersite-background-color;
    box-shadow: $intersite-box-shadow;
    display: none;
    padding: .4rem 0;
    position: relative;
    z-index: $intersite-z-index;

    @include screen-small-up() {
        display: block;
    }
}

.header-intersite__ocado {
    align-items: center;
    display: flex;
}

.intersite-ocado__logo {
    @include size(rem(73px) rem(16px));

    @include screen-medium-up() {
        @include size(rem(91px) rem(20px));
    }
}

.header-intersite__logo--disabled {
    opacity: $global-disabled-opacity;
}

.header-intersite__departmentstores-logos {
    display: flex;
    justify-content: flex-end;
    text-align: right; // Fixes <IE11 alignment issues
}

.header-intersite__fetch-logo {
    @include size(rem(55px) rem(20px));
    margin-left: rem(10px);

    @include screen-medium-up() {
        @include size(rem(63px) rem(26px));
    }
}

.header-intersite__sizzle-logo {
    @include size(rem(72px) rem(22px));
    margin-left: rem(10px);

    @include screen-medium-up() {
        @include size(rem(85px) rem(26px));
    }
}

.intersite__information {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.intersite-information__delivery {
    @include text-default();
    font-size: rem(11px);
    font-weight: $typography-weight-bold;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }

    @include screen-medium-up() {
        font-size: rem(13px);
    }
}

.intersite-information__terms {
    color: $intersite-terms-text-color;
    display: block; // Makes <IE11 display the element correctly in new line
    font-size: rem(9px);
    font-weight: $typography-weight-regular;

    &:hover {
        text-decoration: none;
    }
}

.header__basket {
    a {
        padding: 0 8px;
        width: auto;
    }

    .button--action {
        border-color: $header-basket-button-border-color;
    }
}
