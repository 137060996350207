@import '../settings/settings.zindex';

.fulfilment-availability__bar {
    font-size: rem(14px);
    font-weight: bold;
    padding-bottom: rem(7px);
    padding-top: rem(7px);
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: $z-index-10;
}

.fulfilment-availability__bar__icon {
    margin-right: rem(5px);
}

.fulfilment-availability__bar--onlySlotAvailable {
    background-color: $color-info;
    color: $color-success-contrast;
}

.fulfilment-availability__bar--notAvailable {
    background-color: $color-error;
    color: $color-error-contrast;
}

.fulfilment-availability__bar--offset {
    position: relative;
    visibility: hidden;
}
