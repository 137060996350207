.util-width-full {
    width: 100% !important;
}

.util-width-auto {
    width: auto !important;
}

.util-height-full {
    height: 100% !important;
}

.util-height-auto {
    height: auto !important;
}
