.content-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: hidden;
}

.content-wrapper--background {
    background-color: #f2f2f2;
}

.container {
    width: 100%; // Divs in "display: flex" element don't take all available width, this fixes that for grid container
}
