// Z-Index Scale (private vars)
// --------------------------------------------------
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;
$z-index-100: 10000;

// Z-Index Applications
// --------------------------------------------------
$z-index-backdrop: $z-index-1;
$z-index-backdrop-content: $z-index-1 + 1;
$z-index-1--dropdown: $z-index-1;
$z-index-popover: $z-index-2;
$z-index-popover-backdrop: $z-index-2 - 1;
$z-index-modal: $z-index-100;
