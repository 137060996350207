@import 'bourbon/app/assets/stylesheets/bourbon';
@import 'bourbon-neat/app/assets/stylesheets/neat-helpers';
@import 'bourbon-neat/app/assets/stylesheets/neat';
@import '../settings/settings';

$container-padding: rem(15px) !default;

.container {
    @include outer-container(map-get($grid-breakpoint-widths, lg));
    padding: 0 $container-padding;
}

.container--adaptive {
    padding: 0;

    @include screen-small-up {
        padding: 0 $container-padding;
    }
}

.container-full {
    margin: 0;
    max-width: none;
    padding: 0;
}

.row {
    @include row;
}

@include make-initial-columns();
@include make-initial-offsets();
@include make-breakpoint-columns();
@include make-breakpoint-offsets();
@include make-hidden-classes();
@include make-visible-x-block-classes();
@include make-visible-x-inline-block-classes();
