$fop-swatch-size: rem(10px);

.catalogue__container {
    display: flex;
    flex-flow: row wrap;
    padding: $spacing-values-xxxs;
}

.catalogue__fop-container {
    padding: $spacing-values-xxs $spacing-values-xxxs;
    width: 50%;

    @include screen-extra-small-up {
        width: 33.3%;
    }
    @include screen-small-up {
        width: 25%;
    }
}

.fop__body {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    text-align: $fop-text-align;
}

.fop__header {
    flex-grow: 1;
    padding: 0 $spacing-values-xxs;
}

.fop__image-link {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding-bottom: $spacing-values-xs;
    // padding increased by the final height of image-bar
    padding-top: $spacing-values-xxs + (rem(1px) + $typography-small-font-size);
    position: relative;
}

.fop__image-overlay {
    position: absolute;
    z-index: $z-index-1;
}

.fop__image-bar {
    background: $fop-image-bar-background;
    border-radius: 0 rem(25px) rem(25px) 0;
    color: $color-white;
    font-weight: lighter;
    max-width: 100%;
    padding-left: rem(15px);
    padding-right: rem(8px);
}

.fop__image-bar--offer {
    background: $promotion-discount-badge-background;
    padding-left: rem(20px);
}

.fop__image-bar--preorder {
    background: $preorder-badge-background;
    padding-left: rem(20px);
}

.fop__image-bar--out-of-stock {
    background: $color-black;
    padding-left: rem(20px);
}

.fop-image__placeholder {
    position: relative;
    width: 100%;

    &:before {
        content: '';
        display: block;
        margin-top: 100%;
    }
}

.fop-image__container {
    align-items: center;
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.fop-badge__container {
    bottom: 0;
    display: flex;
    left: 0;
    margin-bottom: rem(20px);
    position: absolute;
    width: 100%;
}

.fop-badge--icon {
    height: rem(57px);
    margin-bottom: rem(-20px);
    width: rem(49px);

    &:nth-child(n+3) {
        display: none;
    }
}

.fop-badge {
    background: $badge-category-background-color;
    color: white;
    display: inline-block;
    margin: 1px;
    padding: 2px;
    position: relative;
    width: 100%;
    z-index: 1;
}

.fop-carousel__container {
    margin: 0 auto;
    max-width: 100%;
    padding: 0 $spacing-values-xs;
    position: relative;
    width: 1680px;
}

.fop-carousel__nav-button {
    border: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include size(45px);

    > svg {
        height: 100%;
        width: 100%;
    }
}

.fop-carousel__nav-button--next {
    left: $spacing-values-xs;
}

.fop-carousel__nav-button--prev {
    right: $spacing-values-xs;
}

.fop-carousel {
    margin: 0 auto;
    overflow: hidden;
    width: 180px;

    @if ($fop-with-rating == true) {
        min-height: 382px;
    } @else {
        min-height: 337px;
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: auto;

        & > div {
            height: 100%;
        }
    }

    @media screen and (min-width: 480px) {
        width: 360px;
    }

    @media screen and (min-width: 768px) {
        width: 540px;
    }

    @media screen and (min-width: 992px) {
        width: 720px;
    }

    @media screen and (min-width: 1200px) {
        width: 1080px;
    }
}

.fop-carousel__product {
    height: 100%;
    width: 180px;
}

.fop__quick-view {
    background: white;
    color: $color-black;
    font-weight: $typography-weight-bold;
    opacity: .8;
    padding: $spacing-values-xxs;
    position: absolute;
    text-align: center;
    width: 100%;

    &:hover {
        opacity: .9;
    }
}

.fop__image {
    @include size(100% auto);
}

.fop__swatch-container {
    align-items: center;
    background-color: $backdrop-light-inverted-background;
    display: flex;
    justify-content: center;
    min-height: rem(15px);
    position: absolute;
    width: 100%;
}

.fop__swatch {
    @include size($fop-swatch-size);
    border-radius: $fop-swatch-size;
    display: inline-block;
    margin: 0 rem(3px) 0 0;

    &:last-child {
        margin: 0;
    }
}

.fop__swatch--bordered {
    border: 1px solid $color-gray;
}

.fop__brand-name {
    color: $color-black;
    display: $fop-brand-display;
    letter-spacing: 1px;
    margin-bottom: $spacing-values-xxxs;
}

.fop__product-name {
    color: $fop-product-name-color;
    display: block;
    font-size: $em-base;
    font-weight: normal;
}

.fop__product-details {
    padding: 0 $spacing-values-xxs $spacing-values-xxs $spacing-values-xxs;
}

.fop__product-rating {
    align-items: center;
    height: rem(20px);
    justify-content: center;
    margin: $spacing-values-xs 0;

    @if ($fop-with-rating == true) {
        display: flex;
    } @else {
        display: none;
    }
}

.fop__product-fav-and-price {
    align-items: center;
    display: flex;
    justify-content: $fop-fav-and-price-align;
}

.fop__product-price {
    font-weight: bold;
    margin-top: $spacing-values-xxs;
}

.fop__unit-price {
    color: $color-gray;
    font-size: rem(12px);
    font-weight: normal;
    min-height: rem(16px);
    width: 100%;
}

.fop__measure {
    color: $color-gray;
    font-size: $em-base;
    margin-top: $spacing-values-xxxs;
    padding-left: rem(10px);
    width: 100%;
}

.fop__swatch-count {
    color: $color-black;
    @include size($fop-swatch-size);
}

.fop__favourite-icon {
    cursor: pointer;
    fill: $color-gray;
    margin-left: $spacing-values-xxs;

    @include size(20px);
}

.fop__favourite-icon--active {
    fill: $color-pink;
}
